.vis-time-axis {
  position: relative;
  overflow: hidden;
}

.vis-time-axis.vis-foreground {
  width: 100%;
  top: 0;
  left: 0;
}

.vis-time-axis.vis-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-time-axis .vis-text {
  color: #4d4d4d;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 3px;
  position: absolute;
  overflow: hidden;
}

.vis-time-axis .vis-text.vis-measure {
  visibility: hidden;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-vertical {
  border-left: 1px solid;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-vertical-rtl {
  border-right: 1px solid;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-time-axis .vis-grid.vis-major {
  border-color: #bfbfbf;
}

.vis .overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-active {
  box-shadow: 0 0 10px #86d5f8;
}

.vis-custom-time {
  cursor: move;
  z-index: 1;
  background-color: #6e94ff;
  width: 2px;
}

.vis-custom-time > .vis-custom-time-marker {
  background-color: inherit;
  color: #fff;
  white-space: nowrap;
  cursor: initial;
  z-index: inherit;
  padding: 3px 5px;
  font-size: 12px;
  top: 0;
}

.vis-current-time {
  z-index: 1;
  pointer-events: none;
  background-color: #ff7f6e;
  width: 2px;
}

.vis-rolling-mode-btn {
  cursor: pointer;
  opacity: .8;
  color: #fff;
  text-align: center;
  background: #3876c2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 7px;
  right: 20px;
}

.vis-rolling-mode-btn:before {
  content: "⛶";
}

.vis-rolling-mode-btn:hover {
  opacity: 1;
}

.vis-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
}

.vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
  border: 1px #bfbfbf;
}

.vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right {
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: hidden;
}

.vis-left.vis-panel.vis-vertical-scroll, .vis-right.vis-panel.vis-vertical-scroll {
  height: 100%;
  overflow: hidden scroll;
}

.vis-left.vis-panel.vis-vertical-scroll {
  direction: rtl;
}

.vis-left.vis-panel.vis-vertical-scroll .vis-content, .vis-right.vis-panel.vis-vertical-scroll {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll .vis-content {
  direction: rtl;
}

.vis-panel.vis-center, .vis-panel.vis-top, .vis-panel.vis-bottom {
  border-left-style: solid;
  border-right-style: solid;
}

.vis-background {
  overflow: hidden;
}

.vis-panel > .vis-content {
  position: relative;
}

.vis-panel .vis-shadow {
  width: 100%;
  height: 1px;
  position: absolute;
  box-shadow: 0 0 10px #000c;
}

.vis-panel .vis-shadow.vis-top {
  top: -1px;
  left: 0;
}

.vis-panel .vis-shadow.vis-bottom {
  bottom: -1px;
  left: 0;
}

.vis-graph-group0 {
  fill: #4f81bd;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #4f81bd;
}

.vis-graph-group1 {
  fill: #f79646;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #f79646;
}

.vis-graph-group2 {
  fill: #8c51cf;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #8c51cf;
}

.vis-graph-group3 {
  fill: #75c841;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #75c841;
}

.vis-graph-group4 {
  fill: #ff0100;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #ff0100;
}

.vis-graph-group5 {
  fill: #37d8e6;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #37d8e6;
}

.vis-graph-group6 {
  fill: #042662;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #042662;
}

.vis-graph-group7 {
  fill: #00ff26;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #00ff26;
}

.vis-graph-group8 {
  fill: #f0f;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #f0f;
}

.vis-graph-group9 {
  fill: #8f3938;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #8f3938;
}

.vis-timeline .vis-fill {
  fill-opacity: .1;
  stroke: none;
}

.vis-timeline .vis-bar {
  fill-opacity: .5;
  stroke-width: 1px;
}

.vis-timeline .vis-point {
  stroke-width: 2px;
  fill-opacity: 1;
}

.vis-timeline .vis-legend-background {
  stroke-width: 1px;
  fill-opacity: .9;
  fill: #fff;
  stroke: #c2c2c2;
}

.vis-timeline .vis-outline {
  stroke-width: 1px;
  fill-opacity: 1;
  fill: #fff;
  stroke: #e5e5e5;
}

.vis-timeline .vis-icon-fill {
  fill-opacity: .3;
  stroke: none;
}

.vis-timeline {
  box-sizing: border-box;
  border: 1px solid #bfbfbf;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.vis-loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis [class*="span"] {
  width: auto;
  min-height: 0;
}

.vis-item {
  color: #1a1a1a;
  z-index: 1;
  background-color: #d5ddf6;
  border-width: 1px;
  border-color: #97b0f8;
  display: inline-block;
  position: absolute;
}

.vis-item.vis-selected {
  z-index: 2;
  background-color: #fff785;
  border-color: #ffc200;
}

.vis-editable.vis-selected {
  cursor: move;
}

.vis-item.vis-point.vis-selected {
  background-color: #fff785;
}

.vis-item.vis-box {
  text-align: center;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-point {
  background: none;
}

.vis-item.vis-dot {
  border-style: solid;
  border-width: 4px;
  border-radius: 4px;
  padding: 0;
  position: absolute;
}

.vis-item.vis-range {
  box-sizing: border-box;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-background {
  box-sizing: border-box;
  background-color: #d5ddf666;
  border: none;
  margin: 0;
  padding: 0;
}

.vis-item .vis-item-overflow {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.vis-item-visible-frame {
  white-space: nowrap;
}

.vis-item.vis-range .vis-item-content {
  display: inline-block;
  position: relative;
}

.vis-item.vis-background .vis-item-content {
  display: inline-block;
  position: absolute;
}

.vis-item.vis-line {
  border-left-style: solid;
  border-left-width: 1px;
  width: 0;
  padding: 0;
  position: absolute;
}

.vis-item .vis-item-content {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px;
}

.vis-item .vis-onUpdateTime-tooltip {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  -o-transition: .4s;
  background: #4f81bd;
  border-radius: 1px;
  width: 200px;
  padding: 5px;
  transition: all .4s;
  position: absolute;
}

.vis-item .vis-delete, .vis-item .vis-delete-rtl {
  box-sizing: border-box;
  cursor: pointer;
  -o-transition: background .2s linear;
  width: 24px;
  height: 24px;
  padding: 0 5px;
  transition: background .2s linear;
  position: absolute;
  top: 0;
}

.vis-item .vis-delete {
  right: -24px;
}

.vis-item .vis-delete-rtl {
  left: -24px;
}

.vis-item .vis-delete:after, .vis-item .vis-delete-rtl:after {
  content: "×";
  color: red;
  -o-transition: color .2s linear;
  font-family: arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  transition: color .2s linear;
}

.vis-item .vis-delete:hover, .vis-item .vis-delete-rtl:hover {
  background: red;
}

.vis-item .vis-delete:hover:after, .vis-item .vis-delete-rtl:hover:after {
  color: #fff;
}

.vis-item .vis-drag-center {
  cursor: move;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-item.vis-range .vis-drag-left {
  cursor: w-resize;
  width: 24px;
  min-width: 2px;
  max-width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -4px;
}

.vis-item.vis-range .vis-drag-right {
  cursor: e-resize;
  width: 24px;
  min-width: 2px;
  max-width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -4px;
}

.vis-range.vis-item.vis-readonly .vis-drag-left, .vis-range.vis-item.vis-readonly .vis-drag-right {
  cursor: auto;
}

.vis-item.vis-cluster {
  vertical-align: center;
  text-align: center;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-cluster-line {
  border-left-style: solid;
  border-left-width: 1px;
  width: 0;
  padding: 0;
  position: absolute;
}

.vis-item.vis-cluster-dot {
  border-style: solid;
  border-width: 4px;
  border-radius: 4px;
  padding: 0;
  position: absolute;
}

div.vis-tooltip {
  visibility: hidden;
  white-space: nowrap;
  color: #000;
  pointer-events: none;
  z-index: 5;
  background-color: #f5f4ed;
  border: 1px solid #808074;
  border-radius: 3px;
  padding: 5px;
  font-family: verdana;
  font-size: 14px;
  position: absolute;
  box-shadow: 3px 3px 10px #0003;
}

.vis-itemset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

.vis-itemset .vis-background, .vis-itemset .vis-foreground {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
}

.vis-axis {
  z-index: 1;
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
}

.vis-foreground .vis-group {
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
  position: relative;
}

.vis-foreground .vis-group:last-child {
  border-bottom: none;
}

.vis-nesting-group {
  cursor: pointer;
}

.vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  background: #f5f5f5;
}

.vis-label.vis-nested-group.vis-group-level-0 {
  background-color: #fff;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-0 .vis-inner {
  padding-left: 0;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-0 .vis-inner {
  padding-right: 0;
}

.vis-label.vis-nested-group.vis-group-level-1 {
  background-color: #0000000d;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding-left: 15px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding-right: 15px;
}

.vis-label.vis-nested-group.vis-group-level-2 {
  background-color: #0000001a;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-2 .vis-inner {
  padding-left: 30px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-2 .vis-inner {
  padding-right: 30px;
}

.vis-label.vis-nested-group.vis-group-level-3 {
  background-color: #00000026;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-3 .vis-inner {
  padding-left: 45px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-3 .vis-inner {
  padding-right: 45px;
}

.vis-label.vis-nested-group.vis-group-level-4 {
  background-color: #0003;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-4 .vis-inner {
  padding-left: 60px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-4 .vis-inner {
  padding-right: 60px;
}

.vis-label.vis-nested-group.vis-group-level-5 {
  background-color: #00000040;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-5 .vis-inner {
  padding-left: 75px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-5 .vis-inner {
  padding-right: 75px;
}

.vis-label.vis-nested-group.vis-group-level-6 {
  background-color: #0000004d;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-6 .vis-inner {
  padding-left: 90px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-6 .vis-inner {
  padding-right: 90px;
}

.vis-label.vis-nested-group.vis-group-level-7 {
  background-color: #00000059;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-7 .vis-inner {
  padding-left: 105px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-7 .vis-inner {
  padding-right: 105px;
}

.vis-label.vis-nested-group.vis-group-level-8 {
  background-color: #0006;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-8 .vis-inner {
  padding-left: 120px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-8 .vis-inner {
  padding-right: 120px;
}

.vis-label.vis-nested-group.vis-group-level-9 {
  background-color: #00000073;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-9 .vis-inner {
  padding-left: 135px;
}

.vis-rtl .vis-label.vis-nested-group.vis-group-level-9 .vis-inner {
  padding-right: 135px;
}

.vis-label.vis-nested-group {
  background-color: #00000080;
}

.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 150px;
}

.vis-rtl .vis-label.vis-nested-group .vis-inner {
  padding-right: 150px;
}

.vis-group-level-unknown-but-gte1 {
  border: 1px solid red;
}

.vis-label.vis-nesting-group:before, .vis-label.vis-nesting-group:before {
  width: 15px;
  display: inline-block;
}

.vis-label.vis-nesting-group.expanded:before {
  content: "▼";
}

.vis-label.vis-nesting-group.collapsed:before {
  content: "▶";
}

.vis-rtl .vis-label.vis-nesting-group.collapsed:before {
  content: "◀";
}

.vis-ltr .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  padding-left: 15px;
}

.vis-rtl .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  padding-right: 15px;
}

.vis-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-labelset {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.vis-labelset .vis-label {
  color: #4d4d4d;
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.vis-labelset .vis-label.draggable {
  cursor: pointer;
}

.vis-group-is-dragging {
  background: #0000001a;
}

.vis-labelset .vis-label:last-child {
  border-bottom: none;
}

.vis-labelset .vis-label .vis-inner {
  padding: 5px;
  display: inline-block;
}

.vis-labelset .vis-label .vis-inner.vis-hidden {
  padding: 0;
}

div.vis-configuration {
  float: left;
  font-size: 12px;
  display: block;
  position: relative;
}

div.vis-configuration-wrapper {
  width: 700px;
  display: block;
}

div.vis-configuration-wrapper:after {
  clear: both;
  content: "";
  display: block;
}

div.vis-configuration.vis-config-option-container {
  background-color: #fff;
  border: 2px solid #f7f8fa;
  border-radius: 4px;
  width: 495px;
  margin-top: 20px;
  padding-left: 5px;
  display: block;
  left: 10px;
}

div.vis-configuration.vis-config-button {
  vertical-align: middle;
  cursor: pointer;
  background-color: #f7f8fa;
  border: 2px solid #ceced0;
  border-radius: 4px;
  width: 495px;
  height: 25px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 5px;
  line-height: 25px;
  display: block;
  left: 10px;
}

div.vis-configuration.vis-config-button.hover {
  color: #fff;
  background-color: #4588e6;
  border: 2px solid #214373;
}

div.vis-configuration.vis-config-item {
  float: left;
  vertical-align: middle;
  width: 495px;
  height: 25px;
  line-height: 25px;
  display: block;
}

div.vis-configuration.vis-config-item.vis-config-s2 {
  background-color: #f7f8fa;
  border-radius: 3px;
  padding-left: 5px;
  left: 10px;
}

div.vis-configuration.vis-config-item.vis-config-s3 {
  background-color: #e4e9f0;
  border-radius: 3px;
  padding-left: 5px;
  left: 20px;
}

div.vis-configuration.vis-config-item.vis-config-s4 {
  background-color: #cfd8e6;
  border-radius: 3px;
  padding-left: 5px;
  left: 30px;
}

div.vis-configuration.vis-config-header {
  font-size: 18px;
  font-weight: bold;
}

div.vis-configuration.vis-config-label {
  width: 120px;
  height: 25px;
  line-height: 25px;
}

div.vis-configuration.vis-config-label.vis-config-s3 {
  width: 110px;
}

div.vis-configuration.vis-config-label.vis-config-s4 {
  width: 100px;
}

div.vis-configuration.vis-config-colorBlock {
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 2px;
  width: 30px;
  height: 19px;
  margin: 0;
  padding: 0;
  top: 1px;
}

input.vis-configuration.vis-config-checkbox {
  left: -5px;
}

input.vis-configuration.vis-config-rangeinput {
  pointer-events: none;
  width: 60px;
  margin: 0;
  padding: 1px;
  position: relative;
  top: -5px;
}

input.vis-configuration.vis-config-range {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0 solid #fff;
  width: 300px;
  height: 20px;
}

input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
  background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  width: 300px;
  height: 5px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#dedede", endColorstr= "#c8c8c8", GradientType= 0);
  background: -moz-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: linear-gradient(#dedede 0%, #c8c8c8 99%);
  border: 1px solid #999;
  border-radius: 3px;
  box-shadow: 0 0 3px #aaa;
}

input.vis-configuration.vis-config-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: -o-linear-gradient(top, #3876c2 0%, #385380 100%);
  background: -ms-linear-gradient(top, #3876c2 0%, #385380 100%);
  width: 17px;
  height: 17px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#3876c2", endColorstr= "#385380", GradientType= 0);
  background: -moz-linear-gradient(top, #3876c2 0%, #385380 100%);
  background: -o-linear-gradient(top, #3876c2 0%, #385380 100%);
  background: linear-gradient(#3876c2 0%, #385380 100%);
  border: 1px solid #14334b;
  border-radius: 50%;
  margin-top: -7px;
  box-shadow: 0 0 1px #111927;
}

input.vis-configuration.vis-config-range:focus {
  outline: none;
}

input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
  background: -o-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#9d9d9d", endColorstr= "#c8c8c8", GradientType= 0);
  background: -moz-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%);
  background: -o-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%);
  background: linear-gradient(#9d9d9d 0%, #c8c8c8 99%);
}

input.vis-configuration.vis-config-range::-moz-range-track {
  background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  width: 300px;
  height: 10px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#dedede", endColorstr= "#c8c8c8", GradientType= 0);
  background: -moz-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%);
  background: linear-gradient(#dedede 0%, #c8c8c8 99%);
  border: 1px solid #999;
  border-radius: 3px;
  box-shadow: 0 0 3px #aaa;
}

input.vis-configuration.vis-config-range::-moz-range-thumb {
  background: #385380;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

input.vis-configuration.vis-config-range:-moz-focusring {
  outline-offset: -1px;
  outline: 1px solid #fff;
}

input.vis-configuration.vis-config-range::-ms-track {
  color: #0000;
  background: none;
  border-width: 6px 0;
  border-color: #0000;
  width: 300px;
  height: 5px;
}

input.vis-configuration.vis-config-range::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input.vis-configuration.vis-config-range::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input.vis-configuration.vis-config-range::-ms-thumb {
  background: #385380;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
  background: #888;
}

input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
  background: #ccc;
}

.vis-configuration-popup {
  text-align: center;
  color: #fff;
  background: #394c59d9;
  border: 2px solid #f2faff;
  border-radius: 4px;
  width: 150px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  transition: opacity .3s ease-in-out;
  position: absolute;
}

.vis-configuration-popup:after, .vis-configuration-popup:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.vis-configuration-popup:after {
  border-width: 8px;
  border-color: #88b7d500 #88b7d500 #88b7d500 #394c59d9;
  margin-top: -8px;
}

.vis-configuration-popup:before {
  border-width: 12px;
  border-color: #c2e1f500 #c2e1f500 #c2e1f500 #f2faff;
  margin-top: -12px;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
  border-bottom: 1px solid;
  width: 100%;
  height: 0;
  position: absolute;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-major {
  border-color: #bfbfbf;
}

.vis-data-axis .vis-y-axis.vis-major {
  color: #4d4d4d;
  white-space: nowrap;
  width: 100%;
  position: absolute;
}

.vis-data-axis .vis-y-axis.vis-major.vis-measure {
  visibility: hidden;
  border: 0;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-minor {
  color: #bebebe;
  white-space: nowrap;
  width: 100%;
  position: absolute;
}

.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
  visibility: hidden;
  border: 0;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-title {
  color: #4d4d4d;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.vis-data-axis .vis-y-axis.vis-title.vis-measure {
  visibility: hidden;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-title.vis-left {
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 100%;
  bottom: 0;
  transform: rotate(-90deg);
}

.vis-data-axis .vis-y-axis.vis-title.vis-right {
  transform-origin: 100% 100%;
  bottom: 0;
  transform: rotate(90deg);
}

.vis-legend {
  background-color: #f7fcffa6;
  border: 1px solid #b3b3b3;
  padding: 5px;
  box-shadow: 2px 2px 10px #9a9a9a8c;
}

.vis-legend-text {
  white-space: nowrap;
  display: inline-block;
}
/*# sourceMappingURL=path0.a4e9d2eb.css.map */
